import { Typography, Box, Grid, Paper, Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useGlobal } from "reactn";
import { Redirect } from "react-router-dom";
import axios from "axios";
import apiRoot from "../../apiRoot";
import decrypt from "../../decrypt";

const TaxApproval = (props) => {
  let [decryption] = useGlobal("decryption");
  let [forms, setForms] = useState(null);
  let [index, setIndex] = useState(null);
  let [edata, setEdata] = useState(null);

  const getForms = async () => {
    const response = await axios.post(
      apiRoot,
      {
        query: `
      query getForms {
        admin {
          paymentProfiles(filterTaxApproved: false) {
            id
            name
            taxFormType
            fullUnencryptedTaxData
            encryptedTaxData
          }
        }
      }`,
        variables: { id: Number(props.match.params.id) },
      },
      { withCredentials: true }
    );
    setForms(response.data.data.admin.paymentProfiles);
    setIndex(0);
  };

  const approve = async (id) => {
    await axios.post(
      apiRoot,
      {
        query: `
      mutation approveTax($id: Int!) {
        admin {
          approveTax(id: $id)
        }
      }`,
        variables: { id },
      },
      { withCredentials: true }
    );
    setForms(forms.filter((i) => i.id !== id));
  };

  useEffect(() => {
    async function dec() {
      if (forms && forms[index] && decryption)
        setEdata(
          await decrypt(
            forms[index].encryptedTaxData,
            decryption.key,
            decryption.passphrase
          )
        );
    }
    dec();
  }, [forms, index, decryption]);

  // eslint-disable-next-line
  useEffect(() => getForms(), [setForms]);

  if (!forms) return <Typography variant="subtitle1">Loading...</Typography>;

  const form = forms[index];
  const fdata = form && JSON.parse(form.fullUnencryptedTaxData);
  let jedata = {};
  if (edata) jedata = JSON.parse(edata);
  const metadata = JSON.parse(localStorage.getItem("tin_check_metadata")) || [];
  return (
    <Box m={2}>
      {!decryption && <Redirect to="/decryption" />}
      <Typography variant="h4">Tax Approval Queue</Typography>
      <Grid container spacing={3} style={{ marginTop: "20px" }}>
        <Grid item xs={8}>
          {form && form.taxFormType === "W9" && (
            <Paper style={{ padding: "20px" }}>
              <Typography variant="h6">Form W-9</Typography>
              <br />
              <Typography variant="subtitle2">Line 1 - Name</Typography>
              <Typography variant="body2">{fdata.name}</Typography>
              <br />
              <Typography variant="subtitle2">
                Line 2 - Business Name
              </Typography>
              <Typography variant="body2">{fdata.businessName}</Typography>
              <br />
              <Typography variant="subtitle2">
                Line 3 - Tax Classification
              </Typography>
              <Typography variant="body2">{fdata.classification}</Typography>
              <br />
              <Typography variant="subtitle2">
                Line 4 - Exempt Payee Code
              </Typography>
              <Typography variant="body2">{fdata.exemptPayeeCode}</Typography>
              <br />
              <Typography variant="subtitle2">Line 5-6 - Address</Typography>
              <Typography variant="body2">{fdata.addressLine1}</Typography>
              <Typography variant="body2">
                {fdata.city}, {fdata.state} {fdata.zip}
              </Typography>
              <br />
              <Typography variant="h6">
                Part I - Taxpayer Identification Number
              </Typography>
              <Typography variant="subtitle2">TIN Type</Typography>
              <Typography variant="body2">{jedata.tinType}</Typography>
              <br />
              <Typography variant="subtitle2">
                Taxpayer Identification Number
              </Typography>
              <Typography variant="body2">{jedata.tin}</Typography>
              {!metadata.filter((i) => i.id === form.id)[0] && (
                <Typography
                  variant="overline"
                  style={{
                    padding: "4px 8px",
                    background: "rgba(0,0,0,0.1)",
                    fontWeight: "500",
                    color: "black",
                    borderRadius: "2px",
                  }}
                >
                  NOT APPROVED
                </Typography>
              )}
              {metadata.filter((i) => i.id === form.id)[0] && (
                <div>
                  {metadata.filter((i) => i.id === form.id)[0].accepted ? (
                    <Typography
                      variant="overline"
                      style={{
                        padding: "4px 8px",
                        background: "rgba(0,0,0,0.1)",
                        fontWeight: "500",
                        color: "black",
                        borderRadius: "2px",
                      }}
                    >
                      APPROVED
                    </Typography>
                  ) : (
                    <Typography
                      variant="overline"
                      style={{
                        padding: "4px 8px",
                        background: "rgba(0,0,0,0.1)",
                        fontWeight: "500",
                        color: "black",
                        borderRadius: "2px",
                      }}
                    >
                      NOT APPROVED
                    </Typography>
                  )}
                  {metadata.filter((i) => i.id === form.id)[0].comment && (
                    <Typography variant="body2">
                      {metadata.filter((i) => i.id === form.id)[0].comment}
                    </Typography>
                  )}
                </div>
              )}
              <br />
              <Typography variant="h6">Part II - Certification</Typography>
              <Typography variant="body2">{fdata.certificationName}</Typography>
            </Paper>
          )}
          {form && form.taxFormType === "W8BEN" && (
            <Paper style={{ padding: "20px" }}>
              <Typography variant="h6">Form W-8BEN</Typography>
              <br />
              <Typography variant="subtitle2">Line 1 - Name</Typography>
              <Typography variant="body2">{fdata.name}</Typography>
              <br />
              <Typography variant="subtitle2">Line 2 - Country</Typography>
              <Typography variant="body2">{fdata.country}</Typography>
              <br />
              <Typography variant="subtitle2">
                Line 3 - Residence Address
              </Typography>
              <Typography variant="body2">
                {fdata.residenceAddressLine1}
              </Typography>
              <Typography variant="body2">
                {fdata.residenceAddressCityState},{" "}
                {fdata.residenceAddressCountry}
              </Typography>{" "}
              <br />
              <Typography variant="subtitle2">
                Line 4 - Mailing Address
              </Typography>
              <Typography variant="body2">
                {fdata.mailingAddressLine1}
              </Typography>
              <Typography variant="body2">
                {fdata.mailingAddressCityState}, {fdata.mailingAddressCountry}
              </Typography>
              <br />
              <Typography variant="subtitle2">Line 5 - US SSN/ITIN</Typography>
              <Typography variant="body2">{jedata.tin}</Typography>
              {metadata.filter((i) => i.id === form.id)[0] && (
                <div>
                  {metadata.filter((i) => i.id === form.id)[0].accepted ? (
                    <Typography
                      variant="overline"
                      style={{
                        padding: "4px 8px",
                        background: "rgba(0,0,0,0.1)",
                        fontWeight: "500",
                        color: "black",
                        borderRadius: "2px",
                      }}
                    >
                      APPROVED
                    </Typography>
                  ) : (
                    <Typography
                      variant="overline"
                      style={{
                        padding: "4px 8px",
                        background: "rgba(0,0,0,0.1)",
                        fontWeight: "500",
                        color: "black",
                        borderRadius: "2px",
                      }}
                    >
                      NOT APPROVED
                    </Typography>
                  )}
                  {metadata.filter((i) => i.id === form.id)[0].comment && (
                    <Typography variant="body2">
                      {metadata.filter((i) => i.id === form.id)[0].comment}
                    </Typography>
                  )}
                </div>
              )}
              <br />
              <Typography variant="subtitle2">Line 6 - Foreign TIN</Typography>
              <Typography variant="body2">{jedata.foreignTin}</Typography>
              <br />
              <Typography variant="subtitle2">
                Line 8 - Date of birth
              </Typography>
              <Typography variant="body2">{jedata.dateOfBirth}</Typography>
              <br />
              <Typography variant="h6">
                Part II - Claim of Treaty Benefits
              </Typography>
              <Typography variant="body2">
                {fdata.treatyCountry
                  ? `Auto-claim in ${fdata.treatyCountry}`
                  : `None`}
              </Typography>
              <br />
              <Typography variant="h6">Part III - Certification</Typography>
              <Typography variant="body2">{fdata.certificationName}</Typography>
            </Paper>
          )}
          {form && form.taxFormType === "W8BENE" && (
            <Paper style={{ padding: "20px" }}>
              <Typography variant="h6">Form W-8BEN-E</Typography>
              <br />
              <Typography variant="subtitle2">Line 1 - Name</Typography>
              <Typography variant="body2">{fdata.name}</Typography>
              <br />
              <Typography variant="subtitle2">Line 2 - Country</Typography>
              <Typography variant="body2">{fdata.country}</Typography>
              <br />
              <Typography variant="subtitle2">
                Line 4 - Chapter 3 Status
              </Typography>
              <Typography variant="body2">{fdata.chapter3Status}</Typography>
              <br />
              <Typography variant="subtitle2">
                Line 6 - Residence Address
              </Typography>
              <Typography variant="body2">
                {fdata.residenceAddressLine1}
              </Typography>
              <Typography variant="body2">
                {fdata.residenceAddressCityState},{" "}
                {fdata.residenceAddressCountry}
              </Typography>{" "}
              <br />
              <Typography variant="subtitle2">
                Line 7 - Mailing Address
              </Typography>
              <Typography variant="body2">
                {fdata.mailingAddressLine1}
              </Typography>
              <Typography variant="body2">
                {fdata.mailingAddressCityState}, {fdata.mailingAddressCountry}
              </Typography>
              <br />
              <Typography variant="subtitle2">Line 8 - US TIN</Typography>
              <Typography variant="body2">{jedata.tin}</Typography>
              {metadata.filter((i) => i.id === form.id)[0] && (
                <div>
                  {metadata.filter((i) => i.id === form.id)[0].accepted ? (
                    <Typography
                      variant="overline"
                      style={{
                        padding: "4px 8px",
                        background: "rgba(0,0,0,0.1)",
                        fontWeight: "500",
                        color: "black",
                        borderRadius: "2px",
                      }}
                    >
                      APPROVED
                    </Typography>
                  ) : (
                    <Typography
                      variant="overline"
                      style={{
                        padding: "4px 8px",
                        background: "rgba(0,0,0,0.1)",
                        fontWeight: "500",
                        color: "black",
                        borderRadius: "2px",
                      }}
                    >
                      NOT APPROVED
                    </Typography>
                  )}
                  {metadata.filter((i) => i.id === form.id)[0].comment && (
                    <Typography variant="body2">
                      {metadata.filter((i) => i.id === form.id)[0].comment}
                    </Typography>
                  )}
                </div>
              )}
              <br />
              <Typography variant="subtitle2">Line 9b - Foreign TIN</Typography>
              <Typography variant="body2">{jedata.foreignTin}</Typography>
              <br />
              <Typography variant="h6">
                Part III - Claim of Treaty Benefits
              </Typography>
              <Typography variant="body2">
                {fdata.treatyCountry
                  ? `Auto-claim in ${fdata.treatyCountry}`
                  : `None`}
              </Typography>
              <br />
              <Typography variant="h6">Part XXX - Certification</Typography>
              <Typography variant="body2">{fdata.certificationName}</Typography>
            </Paper>
          )}
          {!form && (
            <Paper style={{ padding: "20px" }}>
              <Typography variant="h6">No forms to process</Typography>
            </Paper>
          )}
        </Grid>
        <Grid item xs={4}>
          <Paper style={{ padding: "20px" }}>
            <Typography variant="h6">
              Form {index + 1} of {forms.length}
            </Typography>
            <Typography variant="h6">{forms.length} remaining</Typography>
            <br />
            {form && <Typography variant="h6">{form.name}</Typography>}
            {form && <br />}
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: "10px" }}
              onClick={() => approve(form.id)}
            >
              Approve
            </Button>
            <Button variant="contained" onClick={() => setIndex(index + 1)}>
              Skip
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TaxApproval;
