import React, { useEffect } from "react";
import { Route as BaseRoute, Switch, withRouter } from "react-router-dom";
import clsx from "clsx";
import { useGlobal } from "reactn";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

import Sidebar from "./components/Sidebar";
import Home from "./pages/home/Home";
import Works from "./pages/works/Works";
import CreateRegistration from "./pages/createRegistration/CreateRegistration";
import Registrations from "./pages/registrations/Registrations";
import Registration from "./pages/registration/Registration";
import Invites from "./pages/invites/Invites";
import StatementMonth from "./pages/statementMonth/StatementMonth";
import StatementMonths from "./pages/statementMonths/StatementMonths";
import Decryption from "./pages/decryption/Decryption";
import TaxApproval from "./pages/payments/TaxApproval";
import WithholdingDashboard from "./pages/payments/WithholdingDashboard";
import SendPaypal from "./pages/payments/SendPaypal";
import SendPayouts from "./pages/payments/SendPayouts";
import TINCheck from "./pages/payments/TINCheck";
import PaymentsDashboard from "./pages/payments/Dashboard";
import Acknowledgements from "./pages/Acknowledgements/Acknowledgements";
import Acknowledgement from "./pages/Acknowledgements/Acknowledgement";
import LabelJSONImport from "./pages/LabelJSONImport/LabelJSONImport";
import MatchingTool from "./pages/matchingTool/MatchingTool";
import MarketShareDistribution from "./pages/statementMonth/MarketShareDistribution";

import getAuthState from "./getAuthState";

class ScrollToTopRoute extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { component: Component, ...rest } = this.props;

    return <BaseRoute {...rest} render={(props) => <Component {...props} />} />;
  }
}

const Route = withRouter(ScrollToTopRoute);

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

function App() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [, setReferrer] = useGlobal("referrer");

  useEffect(() => getAuthState(setReferrer), [setReferrer]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Music Inc. Admin Dashboard
          </Typography>
        </Toolbar>
      </AppBar>
      <Sidebar
        theme={theme}
        classes={classes}
        open={open}
        handleDrawerClose={handleDrawerClose}
      />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/works" component={Works} />
          <Route path="/createRegistration" component={CreateRegistration} />
          <Route path="/registrations/:id" component={Registration} />
          <Route path="/registrations" component={Registrations} />
          <Route path="/acknowledgements/:id" component={Acknowledgement} />
          <Route path="/acknowledgements" component={Acknowledgements} />
          <Route path="/invites" component={Invites} />
          <Route path="/statementMonths/:id/match" component={MatchingTool} />
          <Route
            path="/statementMonths/:id/msd"
            component={MarketShareDistribution}
          />
          <Route path="/statementMonths/:id" component={StatementMonth} />
          <Route path="/statementMonths" component={StatementMonths} />
          <Route path="/decryption" component={Decryption} />
          <Route path="/taxApproval" component={TaxApproval} />
          <Route path="/sendPaypal" component={SendPaypal} />
          <Route path="/sendPayouts" component={SendPayouts} />
          <Route path="/tinCheck" component={TINCheck} />
          <Route path="/paymentDashboard" component={PaymentsDashboard} />
          <Route
            path="/withholdingDashboard"
            component={WithholdingDashboard}
          />
          <Route path="/labelJsonImport" component={LabelJSONImport} />
        </Switch>
      </main>
    </div>
  );
}

export default App;
