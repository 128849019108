import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import UploadStatement from "./UploadStatement";
import axios from "axios";
import apiRoot from "../../apiRoot";

const segments = [
  "PublishingAccount",
  "Song",
  "Source",
  "Licensee",
  "commissions",
];

const StatementMonth = (props) => {
  let [statementMonth, setStatementMonth] = useState(null);
  let [confirm, setConfirm] = useState(false);
  let [working, setWorking] = useState(false);
  let [segment, setSegment] = useState(0);
  let [segmentData, setSegmentData] = useState([]);

  const finalizeStatement = async () => {
    if (working || !confirm) return;
    setWorking(true);
    await axios.post(
      apiRoot,
      {
        query: `
      mutation finalizeStatementMonth($id: Int!) {
        admin {
          finalizeStatementMonth(id: $id)
        }
      }`,
        variables: { id: Number(props.match.params.id) },
      },
      { withCredentials: true }
    );
    setWorking(false);
  };

  const getStatementMonth = async () => {
    const response = await axios.post(
      apiRoot,
      {
        query: `
        query getStatementMonth($id: Int!) {
          admin {
            minimumBalance
            clientStatementPeriod(id: $id) {
              id
              statement_month
              finalized
              statements {
                id
                society
                territory
                period
                currency
                exchange_rate
                payment_date
                payment_amount
              }
              totalEarnings
              totalRoyalty
              totalCommission
              totalReferralFee
              netProfit
              totalPayPalAuto
              totalPayPalExists
              unmatchedRowCount
            }
          }
        }`,
        variables: { id: Number(props.match.params.id) },
      },
      { withCredentials: true }
    );
    setStatementMonth({
      ...response.data.data.admin.clientStatementPeriod,
      paypalBalance: Number(
        JSON.parse(response.data.data.admin.minimumBalance).paypalBalance
      ),
    });
  };

  const getSegment = async () => {
    const response = await axios.post(
      apiRoot,
      {
        query:
          segments[segment] === "commissions"
            ? `
        query getStatementMonth($id: Int!) {
          admin {
            clientStatementPeriod(id: $id) {
              commissions {
                recipient
                total
              }
            }
          }
        }`
            : `
        query getStatementMonth($id: Int!) {
          admin {
            clientStatementPeriod(id: $id) {
              royaltiesBy${segments[segment]} {
                field
                fieldValue
                royaltyTotal
                feeTotal
              }
            }
          }
        }`,
        variables: { id: Number(props.match.params.id) },
      },
      { withCredentials: true }
    );
    setSegmentData(
      segments[segment] === "commissions"
        ? response.data.data.admin.clientStatementPeriod.commissions
        : response.data.data.admin.clientStatementPeriod[
            "royaltiesBy" + segments[segment]
          ]
    );
  };

  // eslint-disable-next-line
  useEffect(() => getStatementMonth(), [setStatementMonth]);
  // eslint-disable-next-line
  useEffect(() => getSegment(), [setSegmentData, segment]);

  return (
    <Box m={2}>
      <Typography variant="h4">
        Statement Period: {statementMonth && statementMonth.statement_month}
      </Typography>
      <Grid container spacing={3} style={{ marginTop: "20px" }}>
        <Grid item xs={6}>
          {statementMonth && !statementMonth.finalized && (
            <Paper
              style={{
                padding: "20px",
                marginBottom: "20px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={confirm}
                    onChange={(e) => {
                      setConfirm(e.target.checked);
                    }}
                  />
                }
                label="Confirm"
              />
              <Button
                disabled={working || !confirm}
                color="primary"
                onClick={finalizeStatement}
              >
                Finalize Statement Period
              </Button>
            </Paper>
          )}
          <Paper style={{ padding: "20px" }}>
            <Typography variant="h6">Society Statements</Typography>
            {statementMonth &&
              statementMonth.statements.map((i) => (
                <Box
                  key={i.id}
                  style={{
                    padding: "15px 0",
                    borderBottom: "1px solid rgba(0,0,0,0.1)",
                  }}
                >
                  <Typography variant="h6">{i.society}</Typography>
                  <Typography variant="caption">
                    {i.territory} | {i.period}
                  </Typography>
                  <br />
                  <Typography variant="caption">
                    ${i.payment_amount.toFixed(2)} {i.currency} | Paid{" "}
                    {i.payment_date.substr(0, 10)}
                  </Typography>
                  <br />
                  {i.currency !== "USD" && (
                    <Typography variant="caption">
                      1 {i.currency} = ${i.exchange_rate} USD - paid $
                      {(i.payment_amount * i.exchange_rate).toFixed(2)} USD
                    </Typography>
                  )}
                </Box>
              ))}
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper style={{ padding: "20px" }}>
            <Typography variant="h6">Upload Statement</Typography>
            <UploadStatement id={props.match.params.id} />
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginTop: "20px" }}>
        <Grid item xs={6}>
          {statementMonth && (
            <Paper
              style={{
                padding: "20px",
                marginBottom: "20px",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
                maxWidth: "1200px",
                flexWrap: "wrap",
              }}
            >
              <div style={{ marginRight: "40px" }}>
                <Typography variant="overline">EARNINGS</Typography>
                <Typography variant="h4">
                  ${statementMonth.totalEarnings.toFixed(2)}
                </Typography>
              </div>
              <div style={{ marginRight: "40px" }}>
                <Typography variant="overline">ARTIST ROYALTIES</Typography>
                <Typography variant="h4">
                  ${statementMonth.totalRoyalty.toFixed(2)}
                </Typography>
              </div>
              <div style={{ marginRight: "40px" }}>
                <Typography variant="overline">ADMIN FEES</Typography>
                <Typography variant="h4">
                  ${statementMonth.totalCommission.toFixed(2)}
                </Typography>
              </div>
              <div style={{ marginRight: "40px" }}>
                <Typography variant="overline">REFERRAL COMMISSIONS</Typography>
                <Typography variant="h4">
                  ${statementMonth.totalReferralFee.toFixed(2)}
                </Typography>
              </div>
              <div style={{ marginRight: "40px" }}>
                <Typography variant="overline">NET PROFIT</Typography>
                <Typography variant="h4">
                  ${statementMonth.netProfit.toFixed(2)}
                </Typography>
              </div>
              <div style={{ marginRight: "40px" }}>
                <Typography variant="overline">UNMATCHED ROWS</Typography>
                <Typography variant="h4">
                  {statementMonth.unmatchedRowCount}
                </Typography>
              </div>
            </Paper>
          )}
          {statementMonth && (
            <Paper
              style={{
                padding: "20px",
                marginBottom: "20px",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
                maxWidth: "1200px",
                flexWrap: "wrap",
              }}
            >
              <div style={{ marginRight: "40px" }}>
                <Typography variant="overline">PP AUTO</Typography>
                <Typography variant="h4">
                  ${statementMonth.totalPayPalAuto.toFixed(2)}
                </Typography>
              </div>
              <div style={{ marginRight: "40px" }}>
                <Typography variant="overline">PP EXISTS</Typography>
                <Typography variant="h4">
                  ${statementMonth.totalPayPalExists.toFixed(2)}
                </Typography>
              </div>
              <div style={{ marginRight: "40px" }}>
                <Typography variant="overline">PP BALANCE</Typography>
                <Typography variant="h4">
                  ${statementMonth.paypalBalance.toFixed(2)}
                </Typography>
              </div>
              <div style={{ marginRight: "40px" }}>
                <Typography variant="overline">TOTAL</Typography>
                <Typography variant="h4">
                  $
                  {(
                    statementMonth.totalPayPalAuto +
                    statementMonth.totalPayPalExists +
                    statementMonth.paypalBalance
                  ).toFixed(2)}
                </Typography>
              </div>
            </Paper>
          )}
          {statementMonth?.unmatchedRowCount > 0 && (
            <Button
              variant="contained"
              color="primary"
              component={Link}
              style={{ marginRight: "20px" }}
              to={"/statementMonths/" + props.match.params.id + "/match"}
            >
              Matching Tool
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={"/statementMonths/" + props.match.params.id + "/msd"}
          >
            Market Share Distribution
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Tabs
            value={segment}
            onChange={(_, n) => {
              setSegment(n);
              setSegmentData([]);
            }}
            style={{ margin: "20px 0" }}
          >
            <Tab label="Publishing Accounts" />
            <Tab label="Songs" />
            <Tab label="Sources" />
            <Tab label="Licensees" />
            <Tab label="Commissions" />
          </Tabs>
          <Paper style={{ padding: "20px", marginTop: "20px" }}>
            {segmentData.map((i) =>
              segments[segment] === "commissions" ? (
                <Box
                  m={2}
                  key={i.recipient}
                  style={{
                    paddingBottom: "15px",
                    borderBottom: "1px solid rgba(0,0,0,0.1)",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="h6">{i.recipient}</Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <Typography variant="h6">${i.total.toFixed(2)}</Typography>
                  </div>
                </Box>
              ) : (
                <Box
                  m={2}
                  key={i.fieldValue}
                  style={{
                    paddingBottom: "15px",
                    borderBottom: "1px solid rgba(0,0,0,0.1)",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="h6">{i.fieldValue}</Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <Typography variant="h6">
                      ${i.royaltyTotal.toFixed(2)}
                    </Typography>
                    <Typography variant="caption">
                      ${i.feeTotal.toFixed(2)}
                    </Typography>
                  </div>
                </Box>
              )
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StatementMonth;
