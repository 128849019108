import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import { useGlobal } from "reactn";

const Sidebar = (props) => {
  const [referrer] = useGlobal("referrer");

  if (referrer) {
    return (
      <Drawer
        className={props.classes.drawer}
        variant="persistent"
        anchor="left"
        open={props.open}
        classes={{
          paper: props.classes.drawerPaper,
        }}
      >
        <div className={props.classes.drawerHeader}>
          <IconButton onClick={props.handleDrawerClose}>
            {props.theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem>
            <Typography variant="overline">User Management</Typography>
          </ListItem>
          <ListItem button component={Link} to="/invites">
            <ListItemText>Invites</ListItemText>
          </ListItem>
        </List>
      </Drawer>
    );
  }

  return (
    <Drawer
      className={props.classes.drawer}
      variant="persistent"
      anchor="left"
      open={props.open}
      classes={{
        paper: props.classes.drawerPaper,
      }}
    >
      <div className={props.classes.drawerHeader}>
        <IconButton onClick={props.handleDrawerClose}>
          {props.theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </div>
      <Divider />
      <List>
        <ListItem>
          <Typography variant="overline">Work Registration</Typography>
        </ListItem>
        <ListItem button component={Link} to="/works">
          <ListItemText>Works</ListItemText>
        </ListItem>
        <ListItem button component={Link} to="/registrations">
          <ListItemText>Registrations</ListItemText>
        </ListItem>
        <ListItem button component={Link} to="/acknowledgements">
          <ListItemText>Acknowledgements</ListItemText>
        </ListItem>
        <ListItem button component={Link} to="/createRegistration">
          <ListItemText>New registration</ListItemText>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem>
          <Typography variant="overline">Royalty Statements</Typography>
        </ListItem>
        <ListItem button component={Link} to="/statementMonths">
          <ListItemText>Statement Periods</ListItemText>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem>
          <Typography variant="overline">Payments</Typography>
        </ListItem>
        <ListItem button component={Link} to="/paymentDashboard">
          <ListItemText>Dashboard</ListItemText>
        </ListItem>
        <ListItem button component={Link} to="/tinCheck">
          <ListItemText>TIN Confirmation</ListItemText>
        </ListItem>
        <ListItem button component={Link} to="/taxApproval">
          <ListItemText>Tax Document Approval</ListItemText>
        </ListItem>
        <ListItem button component={Link} to="/withholdingDashboard">
          <ListItemText>Withholding Dashboard</ListItemText>
        </ListItem>
        <ListItem button component={Link} to="/sendPaypal">
          <ListItemText>Send PayPal Payouts</ListItemText>
        </ListItem>
        <ListItem button component={Link} to="/sendPayouts">
          <ListItemText>Send Payouts</ListItemText>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem>
          <Typography variant="overline">User Management</Typography>
        </ListItem>
        <ListItem button component={Link} to="/invites">
          <ListItemText>Invites</ListItemText>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem>
          <Typography variant="overline">Security</Typography>
        </ListItem>
        <ListItem button component={Link} to="/decryption">
          <ListItemText>Decryption</ListItemText>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem>
          <Typography variant="overline">Label Royalties</Typography>
        </ListItem>
        <ListItem button component={Link} to="/labelJsonImport">
          <ListItemText>JSON Import</ListItemText>
        </ListItem>
      </List>
    </Drawer>
  );
};

export default Sidebar;
